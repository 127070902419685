import { ProfileHttp } from './profile.http';
import type {
  IGetSatDashboardParams,
  IGetStatBattlesParams,
  IBaseStatRequestParams,
} from '~/repository/modules/profile/types/statistics';
import type {
  IGetProfileBattlesParams,
  IGetProfileBaseParams,
  IGetProfileWinrateParams,
} from '~/repository/modules/profile/types/battles';
import type { TGetProfileUpgradesRequestDto } from '~/repository/modules/profile/types/upgrades';
import { CurrencyName } from '~/constants/currency.constants';
import type { Colors } from '~/constants/colors.constants';
import type { TCurrencyType } from '~/types/Shared.types';
import type { IStatGeneralState } from '~/features/profile/store/statGeneral/StatGeneral.types';

export class ProfileService {
  private readonly profileHttp = new ProfileHttp();

  public generateLevel() {
    return this.profileHttp.generateLevel();
  }

  public getStatBattles(params: IGetStatBattlesParams) {
    return this.profileHttp.getStatBattles(params);
  }

  public async getStatDashboard(
    params: IGetSatDashboardParams,
    isFake: boolean,
  ): Promise<IStatGeneralState | undefined> {
    const response = await this.profileHttp.getStatDashboard(params);

    if (!response.status) return;
    /** Требуется для обработки состояния, когда у пользователя нет открытых кейсов */
    let bestDrop: Pick<
      IStatGeneralState,
      'bestDropCurrency' | 'bestDropRarity' | 'bestDropImage' | 'bestDropPrice' | 'bestDropName' | 'bestDropType'
    > = {
      bestDropCurrency: CurrencyName.RUB,
      bestDropRarity: 'RARE',
      bestDropImage: '',
      bestDropName: '',
      bestDropPrice: '',
      bestDropType: '',
    };
    if (!response.data.bestDrop) return;
    const from = response.data.bestDrop.from;
    if (!from) return;
    const bestDropCurrency = (isFake ? 'DIAMOND' : response.data.bestDrop.currency) as TCurrencyType;
    const bestDropRarity = response.data.bestDrop.rare.toUpperCase() as keyof typeof Colors.RARITY;
    bestDrop = {
      bestDropCurrency,
      bestDropImage: response.data.bestDrop.img,
      bestDropName: response.data.bestDrop.name,
      // TODO за "обрезание" отвечает компонет SharedCurrency
      bestDropPrice: Number(response.data.bestDrop.price).toFixed(2),
      bestDropRarity,
      bestDropType: response.data.bestDrop.type,
    };
    return {
      from,
      battlesCount: formatNumber(response.data.battlesCount, { dashIfZero: true }),
      ...bestDrop,
      inventoryCount: formatNumber(response.data.inventoryCount, { dashIfZero: true }),
      sendItemsCount: formatNumber(response.data.sendItemsCount, { dashIfZero: true }),
      upgradesCount: formatNumber(response.data.upgradesCount, { dashIfZero: true }),
    };
  }

  public getStatUpgrades(params: IBaseStatRequestParams) {
    return this.profileHttp.getStatUpgrades(params);
  }

  public getBattles(params: IGetProfileBattlesParams) {
    return this.profileHttp.getBattles(params);
  }

  public getBestBattle(params: IGetProfileBaseParams) {
    return this.profileHttp.getBestBattle(params);
  }

  public getUpgrades(params: TGetProfileUpgradesRequestDto) {
    return this.profileHttp.getUpgrades(params);
  }

  public getWinrate(params: IGetProfileWinrateParams) {
    return this.profileHttp.getWinrate(params);
  }
}
