import { defineStore, skipHydrate } from 'pinia';
import { ref } from 'vue';
import type { IUser } from '~/store/user/user.types';
import { useAuthStore } from '~/features/authentication/store/authorization';
import type { IFinanceUser, ISetSteamDataRequestDTO } from '~/repository/modules/user/user.types';
import type { IError } from '~/repository/extensions/error/error.types';
import type { IAppRequest } from '~/types/Shared.types';
import { useAlertStore } from '~/store/alert/alert.store';
import { ErrorCodes } from '~/api/global/errors/codes/codes';
import type { IProfileWinrate } from '~/repository/modules/profile/types/battles';

export const useUserStore = defineStore('global/user', () => {
  // import
  const { $api } = useNuxtApp();
  const UserLogger = LoggerController.getLogger(ELoggerKeys.USER);

  const authStore = useAuthStore();
  const isUserSessionStarted = ref(false);

  const alertStore = useAlertStore();

  // const
  const user = ref<IUser>();

  const isLoaded = ref(true);
  const isUserLoaded = computed(() => user.value && isLoaded.value);

  const getIsFake = computed<boolean>(() => {
    return user.value?.isFake || false;
  });
  const userBalance = computed<number>(() =>
    user.value?.finance.balance && !isNaN(parseFloat(user.value?.finance.balance))
      ? parseFloat(user.value?.finance.balance)
      : 0,
  );
  const getTotalAmountItems = computed<string>(() => user.value?.finance.totalAmountItems || '0');

  const userId = computed((): number | undefined => {
    return user.value?.userId;
  });

  /**
   * Получить профиль пользователя
   * @param {number} userId - id запрашиваемого пользователя
   */
  const getUser = async (userId: number): Promise<IAppRequest<IUser>> => {
    const response = await GlobalUtils.Api.handleRequest(async () => {
      return await $api.user.getUser(userId);
    });

    return response ?? ({} as IAppRequest<IUser>);
  };

  /**
   * Получить профиль текущего пользователя
   */
  const getMe = async (isRefetch = false): Promise<void | IError> => {
    // Чтобы при повторном запросе не триггерить скелетон.
    if (!isRefetch) {
      isLoaded.value = false;
    }

    await GlobalUtils.Api.handleRequest(
      async () => {
        const res = await $api.user.getMe();

        UserLogger.log('Ответ запроса на пользователя; User Response: ', res);

        if (!res) return;
        user.value = res.data;
      },
      async (error) => {
        if (error) {
          await authStore.logout();
        }
      },
    );

    isLoaded.value = true;
  };

  /**
   * Получить винрейт против другого пользователя
   */
  const getWinrate = async (userId: number): Promise<IProfileWinrate> => {
    const resp = await $api.profile.getWinrate({ userId });
    if (resp.status) return resp.data;
    return { win: 0, lose: 0 };
  };

  const setSteamData = async (params: ISetSteamDataRequestDTO) => {
    const response = await $api.user.setSteamData(params);
    alertStore.show({
      type: response.status ? 'success' : 'error',
      title: response.status ? response.data.mesage : ErrorCodes.UNPREDICTED_EXCEPTION,
    });

    if (response.status && user.value && user.value.steam) {
      user.value.steam.link = params.link ?? user.value.steam.link;
      user.value.steam.login = params.login ?? user.value.steam.login;
    }
  };

  const decreaseBalanceOn = (value: number, prop: keyof Omit<IFinanceUser, 'currency'> = 'balance') => {
    if (user.value) {
      const balance = +user.value.finance[prop] - value;
      user.value.finance[prop] = String(balance);
    }
  };

  const increaseBalanceOn = (value: number, prop: keyof Omit<IFinanceUser, 'currency'> = 'balance') => {
    if (user.value) {
      const balance = +user.value.finance[prop] + value;
      user.value.finance[prop] = String(balance);
    }
  };

  const decrementTotalAmountItems = (value: number) => {
    if (user.value) {
      const totalAmountItems = +user.value.finance.totalAmountItems - value;
      user.value.finance.totalAmountItems = String(totalAmountItems);
    }
  };

  const incrementProfit = (value: number) => {
    if (user.value) {
      if (!user.value.statistic) return;
      const finance = +user.value.statistic.finance.profit + value;
      user.value.statistic.finance.profit = String(finance);
    }
  };

  const setNewBalance = (newBalanceValue: number, prop: keyof Omit<IFinanceUser, 'currency'> = 'balance') => {
    if (user.value) {
      user.value.finance[prop] = String(newBalanceValue);
    }
  };

  const $reset = () => {
    user.value = undefined;
  };

  const postUserSession = (url: string) => {
    isUserSessionStarted.value = true;
    return $api.user.postUserSession(url);
  };

  const setIsLoading = (state: boolean) => {
    isLoaded.value = state;
  };

  const route = useRoute();
  const isCurrentUser = computed(() => {
    return Number(route.params.userId) === user.value?.userId;
  });
  const otherUser = ref<IUser>({} as IUser);
  const currentUser = computed<IUser | undefined>(() => {
    return isCurrentUser.value ? user.value : otherUser.value;
  });

  watch(
    isUserLoaded,
    async (isLoaded) => {
      if (isLoaded && !isCurrentUser.value && route.params.userId) {
        const resp = await getUser(Number(route.params.userId));
        otherUser.value = resp.data;
      }
    },
    { immediate: false },
  );

  return {
    $reset,
    decreaseBalanceOn,
    decrementTotalAmountItems,
    getIsFake,
    getMe,
    getTotalAmountItems,
    getUser,
    increaseBalanceOn,
    incrementProfit,
    isLoaded,
    isUserLoaded,
    isUserSessionStarted,
    postUserSession,
    setIsLoading,
    setNewBalance,
    user: skipHydrate(user),
    userBalance,
    userId: skipHydrate(userId),
    setSteamData,
    currentUser,
    isCurrentUser,
    getWinrate,
  };
});
